export default {
  heading: 'Rye, serif',
  body: 'Lato, sans-serif',
  monospace: 'Menlo, monospace',
  display: 'Akronim, cursive',
  display2: 'Akronim, cursive',
  googleFonts: ['Rye', 'Lato', 'Akronim']
  // customFamilies: ['', ''],
  // customUrls: [
  //   'https://gonation.biz/fonts/trend-sans-w00-four/trend-sans-w00-four.css',
  // ],
}
