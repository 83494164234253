export default {
  // ? ==========================
  // ? =====  Multilocation  ====
  // ? ==========================

  multiLocation: {},

  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {
    '.navMenu': {
      backgroundColor: 'black',
      minWidth: 'unset',
      padding: ['2rem 1rem', '', '', '2rem 1rem 1rem', '2rem 2rem 1rem'],
      alignItems: ['center', '', '', 'flex-start']
    },

    '.navItem': {
      margin: '1rem 0rem',
      borderLeft: 'solid 3px',
      borderColor: 'primary',
      paddingLeft: '0.5rem'
    },
    '.logo, .logoScrolled': {
      margin: ['', '', '', '0rem']
    },
    '.socialIconsContainer, .phoneContainer': {
      margin: '0.5rem 0rem',
      ':hover': { color: 'primary' }
    },

    '.phoneContainer': {
      borderTop: '2px solid',
      paddingTop: ['1rem', '', '', ''],
      margin: ['', '', '', 'auto 0rem 0rem']
    }
  },

  footer: {},

  ctaWidget: {},

  // ? ======================
  // ? =====  Reusables  ====
  // ? ======================

  title: {
    fontSize: ['1.75rem', '2rem', '2.5rem'],
    fontFamily: 'heading'
  },
  subtitle: {
    fontSize: ['1.1rem', '1.2rem', '1.3rem'],
    borderBottom: 'solid 1px',
    borderTop: 'solid 1px',
    borderColor: 'primary',
    padding: '0.5rem 0rem',
    opacity: '0.7',
    fontFamily: 'body',
    color: 'primary'
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageHero: {
    '.hero_content_container': {
      margin: '0rem auto',
      width: '90%',
      '.title': {
        variant: 'customVariants.title',
        fontSize: ['1.75rem', '2rem', '2.5rem', '3rem']
      },
      '.subtitle': {
        variant: 'customVariants.subtitle',
        fontSize: ['1.25rem', '1.5rem', '1.75rem', '2rem']
      }
    }
  },

  homepageHeroShout: {
    position: 'absolute',
    left: ['0rem', '', '', '0.25rem'],
    bottom: ['0rem', '', '', '0.25rem'],
    '.shoutWidget': {
      maxHeight: '150px',
      '.title': {
        color: 'primary',
        textTransform: 'uppercase'
      },
      '.text': {
        fontSize: '1rem'
      }
    }
  },

  homepageShout: {},

  homepageAbout: {
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  homepageArtists: {
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  homepageGallery: {
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  locationsTitleBlock: {
    marginTop: '2rem',
    paddingBottom: '1rem',
    '.title': {
      variant: 'customVariants.title'
    }
  },
  locationBoxes1: {
    '.box': {
      flex: ['', '', '1'],
      height: ['400px', '', '500px'],
      justifyContent: 'center',
      margin: '0.5rem',
      transition: 'all ease-in-out 0.3s',
      backgroundPosition: 'center',
      ':hover': {
        backgroundPosition: 'bottom left',
        '.title': {
          fontSize: '2rem',
          textDecoration: 'underline'
        }
      },
      svg: {
        path: {
          fill: 'primary'
        }
      }
    },
    '.title': {
      transition: 'all ease-in-out 0.3s',
      fontSize: '1.5rem',
      fontWeight: 'normal',
      color: 'white',
      marginBottom: '1rem',
      fontFamily: 'heading',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%'
    },
    '.text': {
      color: 'white'
    },
    '.contactDetails-container': {
      // opacity: '',
      a: {
        ':hover': {
          color: 'lightgrey'
        }
      }
    },

    '.socialIconsContainer': {
      display: 'none'
    }
  },

  imageSlider: {},

  homepageContactUs: {},

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {},

  // ? ========================
  // ? ====  Menu page  ====
  // ? ========================

  menu: {
    '.menuItemName': {
      variant: 'customVariants.title'
    },
    '.menuItemButton': {
      variant: 'buttons.primary'
    },
    '.menuSectionDescription': {
      marginBottom: '2rem'
    },
    '.menuItemContentContainer': {
      order: ['2', '', '', 'inherit']
    }
  },

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {
    '.lazyload-wrapper': {
      width: '100%'
    }
  },

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  events: {},

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {},

  locationMap: {}
}
